import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(sessionStorage.getItem('authToken') || '');
  const [userName, setUserName] = useState(sessionStorage.getItem('userName') || '');

  const login = (token, user) => {
    sessionStorage.setItem('authToken', token);
    sessionStorage.setItem('userName', user);
    setAuthToken(token);
    setUserName(user);
  };

  const logout = () => {
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('userName');
    setAuthToken('');
    setUserName('');
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = sessionStorage.getItem('authToken');
      if (!token) {
        logout();
      } else {
        const tokenPayload = JSON.parse(atob(token.split('.')[1]));
        const exp = tokenPayload.exp * 1000;
        if (Date.now() >= exp) {
          logout();
        }
      }
    };

    const interval = setInterval(checkTokenExpiration, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider value={{ authToken, userName, setUserName, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };