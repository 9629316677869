import React, { useRef, useEffect } from 'react';
import { FaUser } from 'react-icons/fa';
import './styles/Chatwindow.css';
import logosvg from '../images/adsecura2.png';

const parseMarkdown = (text) => {
  if (!text) {
    return []; 
  }
  const parts = text.split(/(\*\*[^*]+\*\*|\[.+\]\(http[s]?:\/\/.+?\)|\#{2,3} .+)/g);
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    } else if (part.startsWith('### ')) {
      return <h3 key={index}>{part.slice(4)}</h3>;
    } else if (part.startsWith('## ')) {
      return <h2 key={index}>{part.slice(3)}</h2>;
    } else if (part.match(/\[.+\]\(http[s]?:\/\/.+?\)/)) {
      const match = /\[(.+)\]\((http[s]?:\/\/.+?)\)/.exec(part);
      if (match) {
        return <a href={match[2]} key={index} target="_blank" rel="noopener noreferrer">{match[1]}</a>;
      }
    }
    return part;
  });
};

const ChatWindow = ({ messages = [] }) => {
  const chatWindowRef = useRef(null);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="chat-window" ref={chatWindowRef}>
      <div className="chatbot-messages">
        {messages.map((message, index) => (
          <div key={index} className={`chatbot-message chatbot-message--${message.sender}`}>
            <div className="chatbot-message-content">
              <div className="chatbot-message-title">
                <span className="chatbot-message-user-profile">
                  {message.sender === 'user' ? <FaUser className="icon-user" /> : <img src={logosvg} className="svg" alt="logo" />}
                </span>
                {message.sender === 'user' ? 'Vous' : 'ADAM'}
              </div>
              <div className="chatbot-message-text">
                <pre>
                  {
                    parseMarkdown(message.text || message.content)
                  }
                </pre>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatWindow;
