import React from 'react';
import logo from '../images/logo_adsc.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faRedo } from '@fortawesome/free-solid-svg-icons';
import './styles/Navbar.css';

const Navbar = ({ handleReset }) => {
  return (
    <nav className="navbar">
        <div className="navbar-buttons">
          <div className="tooltip">
            <button className="reset-button" onClick={handleReset}>
              <FontAwesomeIcon icon={faRedo} />
              Reset
            </button>
          </div>
        </div>
    </nav>
  );
};

export default Navbar;