import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo_adsc.png';

const NotFound = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
      backgroundColor: '#f8f8f8',
      padding: '20px',
    },
    image: {
      maxWidth: '40vh',
      height: 'auto',
      marginBottom: '20px',
    },
    heading: {
      fontSize: '72px',
      margin: '0',
      color: '#394460',
    },
    text: {
      fontSize: '24px',
      margin: '20px 0',
      color: '#394460'
    },
    link: {
      fontSize: '18px',
      textDecoration: 'none',
      color: '#007bff',
      border: '1px solid #007bff',
      padding: '10px 20px',
      borderRadius: '5px',
      transition: 'all 0.3s ease',
    },
    linkHover: {
      backgroundColor: '#007bff',
      color: '#fff',
    },
  };

  const handleMouseOver = (e) => {
    e.target.style.backgroundColor = '#007bff';
    e.target.style.color = '#fff';
  };

  const handleMouseOut = (e) => {
    e.target.style.backgroundColor = 'transparent';
    e.target.style.color = '#007bff';
  };

  return (
    <div style={styles.container}>
      <img src={logo} alt="404 Not Found" style={styles.image} />
      <h1 style={styles.heading}>404</h1>
      <p style={styles.text}>Page non trouvée</p>
      <Link
        to="/"
        style={styles.link}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        Retour
      </Link>
    </div>
  );
};

export default NotFound;
