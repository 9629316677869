import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ConnectionPage from './ConnectionPage';
import Chatbot from './Chatbot';
import WithAuthentication from './WithAuthentication';
import CreateAccount from './CreateAccount';
import NotFound from './NotFound';
import { AuthContextProvider } from './AuthContext';

const App = () => {
  return (
    <Router>
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<Chatbot />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthContextProvider>
    </Router>
  );
};

export default App;