import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './styles/Chatbot.css';
import Navbar from './Navbar';
import ChatContainer from './ChatContainer';
import ChatInput from './ChatInput';
import Modal from 'react-modal';

const Chatbot = () => {
  const names = [
    "jane@mkkm.com","nico@etail.com","victor@gmail.com","annabelle@virtuocode.com"
  ];
  const suggestions = [
    "I would like to create a new partner on Adsecura, can you help me?",
    "How are advertisers assigned to an user on Adsecura?",
    "Help me to trigger the verification for a partner in the AdKeeper tool on Adsecura.",
    "Guide me to set up a Brand Lift measurement in DV360.",
    "How do I access the 'BudgetKeeper' application from 'Adsecura'?",
    "What are the procedures to modify a partner on Adsecura?",
    "How to export the list of partners from the Adsecura site?",
    "How to modify an user's license on Adsecura.",
    "How to create a campaign in CM360?"
  ];

  const [userName, setUserName] = useState(() => {
    const savedUserName = localStorage.getItem('userName');
    return savedUserName || '';
  });
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [showCenterLogo, setShowCenterLogo] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingConversation, setIsLoadingConversation] = useState(true);
  const [shouldCallAPI, setShouldCallAPI] = useState(true);
  const [greetingMessage, setGreetingMessage] = useState('');
  const [shuffledSuggestions, setShuffledSuggestions] = useState([]);
  const [ws, setWs] = useState(null);
  const messageBuffer = useRef('');
  const currentBotMessageIndex = useRef(null);
  const retryTimeout = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const emailFromUrl = query.get('username');
    const selectedUserName = emailFromUrl || getRandomUserName();

    setUserName(selectedUserName);
    const shuffled = suggestions.sort(() => 0.5 - Math.random()).slice(0, 4);
    setShuffledSuggestions(shuffled);

    if (selectedUserName) {
      fetchInitialGreeting(selectedUserName);
      fetchConversationHistory(selectedUserName);
    }
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem('userName', userName);
  }, [userName]);

  useEffect(() => {
    const connectWebSocket = () => {
      const websocket = new WebSocket('wss://llm.programmads.com/ws/interact_agent');
      const decoder = new TextDecoder('utf-8');

      websocket.onopen = () => {
        //console.log('WebSocket connection established');
        setWs(websocket);
        if (retryTimeout.current) {
          clearTimeout(retryTimeout.current);
          retryTimeout.current = null;
        }
      };

      websocket.onmessage = (event) => {
        let data = '';
        if (typeof event.data === 'string') {
          data = event.data;
        } else if (event.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            data = decoder.decode(reader.result);
            updateMessage(data);
          };
          reader.readAsArrayBuffer(event.data);
          return;
        } else if (event.data instanceof ArrayBuffer) {
          data = decoder.decode(event.data);
        }
        updateMessage(data);
      };

      websocket.onerror = (error) => {
        //console.error('WebSocket error:', error);
        setIsLoading(false);
        setMessages(currentMessages => {
          const newMessages = currentMessages.filter(msg => msg.text !== 'Loading');
          return [...newMessages, {
            sender: 'bot',
            text: 'Connection Error. Try again later.',
            timestamp: new Date(),
          }];
        });
      };

      websocket.onclose = () => {
        //console.log('WebSocket connection closed');
        setWs(null);
        retryTimeout.current = setTimeout(() => {
          connectWebSocket();
        }, 500);
      };
    };

    connectWebSocket();

    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
      if (retryTimeout.current) {
        clearTimeout(retryTimeout.current);
      }
    };
  }, []);

  const updateMessage = (newData) => {
    messageBuffer.current += newData;
    setIsLoading(false);
    setMessages(currentMessages => {
      const newMessages = currentMessages.slice();
      if (newMessages !== null ) {
        currentBotMessageIndex.current = newMessages.length - 1;
        newMessages[currentBotMessageIndex.current] = {
          ...newMessages[currentBotMessageIndex.current],
          text: messageBuffer.current,
        };
      } else {
        const newBotMessage = {
          sender: 'bot',
          text: messageBuffer.current,
          timestamp: new Date(),
        };
        currentBotMessageIndex.current = newMessages.length;
        newMessages.push(newBotMessage);
      }
      return newMessages;
    });
  };

  const fetchInitialGreeting = async (userName) => {
    setGreetingMessage(`Hello I'm ADAM, your AI Adtech Assistant. How can I help you?`);
  };

  const fetchConversationHistory = async (userName) => {
    setIsLoadingConversation(true);
    try {
      const response = await fetch(`https://llm.programmads.com/conversations/${userName}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch conversation history: ${response.status} - ${response.statusText}`);
      }
      const data = await response.json();
      if (Array.isArray(data)) {
        const formattedMessages = data.map(msg => ({
          sender: msg.type === 'human' ? 'user' : 'bot',
          text: msg.content,
          timestamp: new Date(),
        }));
        setMessages(formattedMessages);
        setShowCenterLogo(formattedMessages.length === 0);
      } else {
        setMessages([]);
        setShowCenterLogo(true);
      }
    } catch (error) {
      console.error('Error fetching conversation history:', error);
    } finally {
      setIsLoadingConversation(false);
    }
  };

  const handleInputChange = useCallback((event) => {
    setUserInput(event.target.value);
  }, []);

  const handleSendClick = useCallback(({ type, payload }) => {
    if (!payload.trim()) return;

    const userMessage = {
      sender: 'user',
      userName,
      text: payload,
      timestamp: new Date(),
    };

    const loadingMessage = {
      sender: 'bot',
      text: 'Chargement',
      timestamp: new Date(),
    };

    setMessages(messages => [...messages, userMessage, loadingMessage]);
    setUserInput('');
    setShowCenterLogo(false);
    setIsLoading(true);

    if (ws && ws.readyState === WebSocket.OPEN) {
      const requestPayload = {
        user_id: userName,
        query: userMessage.text,
      };
      ws.send(JSON.stringify(requestPayload));
      currentBotMessageIndex.current = messages.length + 1;
      messageBuffer.current = '';
    } else {
      console.error('WebSocket is not open');
      setIsLoading(false);
      setMessages(currentMessages => {
        const newMessages = currentMessages.filter(msg => msg.text !== 'Chargement');
        return [...newMessages, {
          sender: 'bot',
          text: 'Unable to send message. WebSocket is not open.',
          timestamp: new Date(),
        }];
      });
    }
  }, [userName, ws]);

  const handleSuggestionClick = (suggestion) => {
    setUserInput(suggestion);
    handleSendClick({ type: 'text', payload: suggestion });
  };

  const handleReset = async () => {
    setShowCenterLogo(true);
    await deleteSession(userName);
    const randomName = getRandomUserName();
    setUserName(randomName);
    fetchInitialGreeting(randomName);

    setMessages([]);
    setUserInput('');
    localStorage.removeItem('userName');
    sessionStorage.clear();
  };

  const deleteSession = async (userName) => {
    try {
      const response = await fetch(`https://llm.programmads.com/conversations/${userName}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to delete session: ${response.status} - ${response.statusText}`);
      }
      //console.log("Conversation history deleted successfully.");
    } catch (error) {
      console.error('Error deleting session:', error);
    }
  };

  const getRandomUserName = () => {
    const savedUserName = localStorage.getItem('userName');
    if (savedUserName) {
      return savedUserName;
    }
    const randomName = names[Math.floor(Math.random() * names.length)];
    localStorage.setItem('userName', randomName);
    return randomName;
  };

  Modal.setAppElement('#root');

  return (
    <div className="flex-container">
      <Navbar isAuthenticated={true} handleReset={handleReset} />
      {isLoadingConversation ? (
        <div className="dot-container">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
      ) : (
        <ChatContainer
          showCenterLogo={showCenterLogo}
          messages={messages}
          isLoading={isLoading}
          suggestions={shuffledSuggestions}
          handleSuggestionClick={handleSuggestionClick}
          greetingMessage={greetingMessage}
        />
      )}
      <ChatInput
        userInput={userInput}
        handleInputChange={handleInputChange}
        handleSendClick={handleSendClick}
        shouldCallAPI={shouldCallAPI}
      />
    </div>
  );
};

export default Chatbot;