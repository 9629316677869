import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const sendMessage = createAsyncThunk(
  'chatbot/sendMessage',
  async ({ userName, request }, { rejectWithValue }) => {
    try {
      console.log('Sending request to Voiceflow:', request);
      const response = await fetch(`https://general-runtime.voiceflow.com/state/user/${encodeURIComponent(userName)}/interact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'VF.DM.663bd142e59bf4b044cc4436.iLObuD1KhKEI9FyU',
        },
        body: JSON.stringify({ request }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Response from Voiceflow:', JSON.stringify(data, null, 2));

      const messages = data.flatMap(trace => {
        if (trace.type === 'speak' && trace.payload?.message) {
          return [{
            sender: 'bot',
            text: trace.payload.message,
            timestamp: new Date(),
          }];
        } else {
          return [];
        }
      });

      return messages;
    } catch (error) {
      console.error('API call error:', error);
      return rejectWithValue('API call error');
    }
  }
);

const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState: {
    messages: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    clearMessages: (state) => {
      state.messages = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.messages.push(...action.payload);
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.messages.push({
          sender: 'bot',
          text: 'Erreur de connexion. Veuillez réessayer.',
          timestamp: new Date(),
        });
      });
  },
});

export const { addMessage, clearMessages } = chatbotSlice.actions;
export default chatbotSlice.reducer;