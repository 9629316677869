import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import './styles/Chatinput.css';

const ChatInput = ({ userInput = '', handleInputChange, handleSendClick }) => {
  const handleInputKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendClick({ type: 'text', payload: userInput });
    }
  }, [handleSendClick, userInput]);

  const onSendClick = () => {
    handleSendClick({ type: 'text', payload: userInput });
  };

  return (
    <div className="chat-input">
      <div className="input-container">
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          className="chatbot-input"
          placeholder="Chat with ADAM..."
        />
        <button
          type="button"
          onClick={onSendClick}
          className="chatbot-send-button"
          disabled={!userInput.trim()}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
